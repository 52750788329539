import React from 'react';
import './features.css';
import social from '../../assets/what_social.svg';
import network from '../../assets/what_network.svg';

const Features = () => {
  return (
    <div className='features' id='features'>


      <div className='features__container'>
        
        <div className='features__image' id='layout'>
          <img src={network} alt='what' />
        </div>

        <div className='features__text' id='justify_r'>
          <h1 className='gradient__text'>Smart Map and Payment</h1>
          <p>
          Owners of electric vehicles can offer their private/household charging station to EV owners who need to recharge their vehicles
          </p>
        </div>

        <div className='features__image' id='layout_'>
          <img src={network} alt='what' />
        </div>

      </div>

      
      <div className='features__container'>
        
        <div className='features__image'>
          <img src={social} alt='what' />
        </div>
        
        <div className='features__text'>
          <h1 className='gradient__text'>Innovative authentication</h1>
          <p>
          Owners of electric vehicles can offer their private/household charging station to EV owners who need to recharge their vehicles
          </p>
        </div>
      
      </div>

      
    </div>
  )
}

export default Features
