import React from 'react'

import { Footer, FAQ, Learnmore, Features, Whatsparx, Header } from './containers'
import { Cta, Brand, Navbar } from './components';
import './App.css'

const App = () => {
  return (
    <div>
      <div className='App'>
          <div className='gradient__bg'>
              <Navbar />
              <Header />
          </div>
          <Brand />
          <Whatsparx />
          <Features />
          <Cta />
          {/* <Learnmore /> */}
          <FAQ />
          <Footer />
      </div>
    </div>
  )
}

export default App
