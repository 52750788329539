import React from 'react';
import './cta.css';

const Cta = () => {
  return (
    <div className='cta gradient__dark' id='learn'>
      <div className='cta__section'>
        
        <div className='cta__section-title'>
          <h1 className='gradient__text'>Sign up now</h1>
          <p>Register your e-mail to stay updated</p>
        </div>
        
        <div className='cta__section-form'>
          <input placeholder='e-mail' />
          <button>Register</button>
        </div>
          
        {/* <div className='cta__section-info'>
          <p id='info_desktop'>By clicking Agree & Join, you agree to the Sparx User Agreement, Privacy Policy, and Cookie Policy.</p>  
          <p id='info_mobile'>Privacy Policy and Cookie Policy</p>
        </div>    */}

      </div>
    </div>
  )
}
export default Cta
