import React from 'react';
import { QnA } from '../../components';
import './faq.css';

const Answer01 = () => (
  <>
  <QnA question='How do I get started?' answer='Whether you are looking to be a driver, host or both, the first step is downloading the Sparx™ app in the Play Store if you have an Android smartphone, or App Store if you have an apple smartphone. Click here to download or search "Sparx" in the App/Play store. Once downloaded Open the app and follow the simple sign up instructions. You will have a few clearly marked sign in options to choose from. The app will immediately be ready for use as Driver for seeking a charger. See the question below for how to register as a host'/>
  </>
)
const Answer02 = () => (
  <>
  <QnA question='How do I become a host?' answer='To become a host simply open the app (the button on the top left). Here you will see Driver/Host functions are clearly separated by colour scheme, with host functions in green. Click on "Host Profile". If you have not already registered the on screen instructions will take you through the simple two minute process. All we all need is a few details about your charger.'/>
  </>
)
const Answer03 = () => (
  <>
  <QnA question='How much will it cost to charge my vehicle?' answer='The owner of the charger can choose their own hourly charger rental rate. Once you have booked this cannot change for the duration of the session, so what you see at the booking screen is what you will pay. You can also filter chargers by cost on the home screen map.'/>
  </>
)
const Answer04 = () => (
  <>
  <QnA question='How much will it cost to list my charger?' answer='Listing your charger is free! We take a small commission from the charging price which you set whenever you get a booking. Some users will even be entitled to limited 0% commission within the terms of their offer! Find out about current offers here. '/>
  </>
)
const Answer05 = () => (
  <>
  <QnA question='Will a driver be able to use my charger without paying?' answer='No. With the Sparx™ app payments are taken before the booked session commences, and the booking will not be possible if funds are not available.'/>
  </>
)
const Answer06 = () => (
  <>
  <QnA question='What happens if my vehicle is damaged whilst charging?' answer='A majority of chargers listed on Sparx™ will be off street, private parking which is recognised as safer than on street alternatives to begin with. However, in the unlikely event your vehicle is damaged, most personal insurance policies will have you covered.'/>
  </>
)

const FAQ = () => {

  return (
    <div className='faq' id="faq">
      <div className='faq__section'>
          <h1 className='gradient__text' id='faq_mobile'>FAQ</h1>
          <h1 className='gradient__text' id='faq_desktop'>Frequently Asked Questions</h1>
          <p id='faq_mobile'>Contact us if you can't find an answer to your question.</p>
          <p id='faq_desktop'>Please don't hesitate to contact us if you cannot find an answer to your question.</p>
          <Answer01 />
          <Answer02 /> 
          <Answer03 />
          <Answer04 /> 
          <Answer05 />
          <Answer06 /> 
      </div>
    </div>
  )
}
export default FAQ
