import React from 'react';
import './whatsparx.css';
import social from '../../assets/what_social.svg';
import charge from '../../assets/what_charge.svg';
import network from '../../assets/what_network.svg';

const SocialDesc = () => (
  <>
  <h1 className='gradient__text'>Social.</h1>
  <p>SPARX introduces the concept of social economy to the world of e-mobility.</p>
  </>
)

const ChargerDesc = () => (
  <>
  <h1 className='gradient__text'>.charger.</h1>
  <p>The system allows maximum monetisation of a public or private charging station through optimised management of its occupancy.</p>
  </>
)

const NetworkDesc = () => (
  <>
  <h1 className='gradient__text'>.Network</h1>
  <p>Owners of electric vehicles can offer their private/household charging station to EV owners who need to recharge their vehicles</p>
  </>
)

const Whatsparx = () => {
  return (
    <div className='whatis gradient__dark' id='service'>
        <div className='whatis__content--mobile'>
          <div className='whatis__content-card'>
            <img src={social} alt='what' />
            <SocialDesc />
          </div>
          <div className='whatis__content-card'>
            <img src={charge} alt='what' />
            <ChargerDesc />
          </div>
          <div className='whatis__content-card'>
            <img src={network} alt='what' />
            <NetworkDesc />
          </div>
        </div>

        <div className='whatis__content--desktop'>
          <div className='whatis__container'>    
              <div className='whatis__content-image'>
                <img src={social} alt='what' />
              </div>
              <div className='whatis__content-desc'>
                <SocialDesc />
              </div>
          </div>
          <div className='whatis__container'>
              <div className='whatis__content-desc justify_right'>
                <ChargerDesc />
              </div>
              <div className='whatis__content-image'>
                <img src={charge} alt='what' />
              </div>
          </div>
          <div className='whatis__container'>    
              <div className='whatis__content-image'>
                <img src={network} alt='what' />
              </div>
              <div className='whatis__content-desc'>
                <NetworkDesc />
              </div>
          </div>
        </div>
    </div>
  )
}

export default Whatsparx
