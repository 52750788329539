import React, { useState } from 'react';
import {RiInstagramLine, RiTwitterLine,} from  'react-icons/ri';
// import {IoIosArrowUp, IoIosArrowDown} from 'react-icons/io';
import flag from '../../assets/flag.svg';
import logo from '../../assets/logo.svg';
import './footer.css';

const Footer = () => {
  const [toggleMenu, setToggleInfo] = useState(false);
  return (
    <div className='footer gradient__dark' id='contact'>
      
      <div className='footer__section_top'>
        <iframe title='map' width="100%" height="100%" id="gmap_canvas" src="https://maps.google.com/maps?q=Manno,%20Switzerland&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
      </div>

        <div className='footer__section_middle' id='about'>
        
          <h1>About us</h1>
          <p>In the summer of 2020, an Italian-Swiss group developed the first demo of SPARX that introduces for the first time the concept of sharing car charges from private charging points.
          <br/><br/>Between 2021 and 2022, the group organized itself and started looking for partnerships, initially in Switzerland and then in Italy, by participating in meetings with public and private investors and sector operators.
          <br/><br/>In the meantime, aspects of SPARX technology have been patented in Europe.
          </p>
        </div>

      <div className='footer__section_bottom'>
        <div className='footer__bottom_1'>
          <img src={logo} alt='logo' />
          <p>
            CH-6928 Manno<br/>Ticino - Switzerland
            <img src={flag} alt='flag' id='flag'/><br/>
            <a href='mailto:info@sparx.social'>info@sparx.social</a><br/>
            </p>
        </div>
         <div className='footer__bottom_3'>
         <a href='http://www.instagram.com'><RiInstagramLine className='footer__icon' id='insta'/></a>
         <a href='http://www.twitter.com'><RiTwitterLine className='footer__icon' id='twit'/></a><br/><br/>
          <a href='/SPARX-Privacy-Policy.pdf' download>Privacy Police</a>
        </div>
      </div>

      <div className='footer__info'>
      <p id='copy_desktop'>© 2021-2023 SPARX - All Rights Reserved</p>
      <p id='copy_mobile'>© 2021-2023 SPARX<br/>All Rights Reserved</p>
      </div>
    </div>
  )
}

export default Footer
