import React, { useState } from 'react';

const QnA = ({question, answer}) => {
  const [toggleMenu, setToggleFaqs] = useState(false);

  return (
    <div className='faq__section_wrapper'>
      <div className='faq__section-question'>
            {toggleMenu
            ?<p onClick={() => setToggleFaqs(false)} id='q_false'>{question}</p>
            :<p onClick={() => setToggleFaqs(true)} id='q_true'>{question}</p>
            }
          </div>
          {toggleMenu && (
          <div className='faq__section-answer fade-in-top'>
            <p>{answer}</p>
          </div>
          )}
    </div>
  )
}
export default QnA