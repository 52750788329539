import React from 'react';
import './brand.css';
import { apple, android } from './import';

const Brand = () => {
  return (
    <div className='brand'>
      <div>
        <img src={apple} alt='store1' />
        <img src={android} alt='store2' />
      </div>
    </div>
  )
}

export default Brand 