import React from 'react';
import './header.css';
import hero from '../../assets/hero.png';
import hero_desktop from '../../assets/hero_desktop.png';
import hero_mobile from '../../assets/hero_mobile.png';

const Header = () => {
  return (
    <div className='header'>
      
      <div className='header__content'>
        <h1 className='gradient__text'>S P A R X</h1>
        <p>Energy transition is a process that is already underway.</p>
        {/* <div className='header__content-input'>
          <button type='button'>Learn More</button>
        </div> */}
        <div className='header__content-people'>
          <p>something about social </p>
        </div>
      </div>
      
      <div className='header_image'>
          <img id='hero' src={hero} alt='hero' />
          <img id='hero_d' src={hero_desktop} alt='hero' />
          <img id='hero_m' src={hero_mobile} alt='hero' />
      </div>
    
    <div className='header__subcontent'>
      <h1 className='gradient__text'>How it works</h1><br/><br/>
    <p>
      The system allows:<br/><br/>
      Owners of electric and non-electric vehicles (<b>HOST</b>) to offer their private/domestic charging station to users who need to recharge their vehicle or simply need to park for a certain period of time.
      <br/><br/>
      Owners of a vehicle (<b>USER</b>), such as an electric car or other electric vehicles like bicycles, scooters, and even gasoline-powered cars, to recharge and/or park at private locations.
      <br/><br/>
      In summary, we connect the demand and supply between <b>HOST</b> and <b>USER</b>, making the use of charging stations efficient through an innovative system that monitors occupancy and automatically authenticates users.<br/><br/><br/><b>Available soon on</b>
    </p>
    </div>

    </div>
    
  )
}

export default Header
