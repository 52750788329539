import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.svg';
import './navbar.css';

const Menu = () => (
  <>
  <p><a href="#home">Home</a></p>
  <p><a href="#service">Service</a></p>
  <p><a href="#features">Features</a></p>
  <p><a href="#learn">Learn</a></p>
  <p><a href="#faq">FAQ</a></p>
  <p><a href="#contact">Contact</a></p>
  </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenus] = useState(false);
  
  return (
    <div className='navbar' id='home'>
      <div className='navbar__links' >
        <div className='navbar__links-logo'>
          <img src={logo} alt='logo' />
        </div>
        <div className='navbar__links-container'>
          <Menu />
        </div>
      </div>
      {/* <div className='navbar__sign'>
          <p><a href='#home'>Sign in</a></p>
          <button type='button'>Sign up</button>
      </div> */}
      <div className='navbar__menu'>
        {toggleMenu
          ?<RiCloseLine color='#121212' size={27} onClick={() => setToggleMenus(false)} />
          :<RiMenu3Line color='#121212' size={27} onClick={() => setToggleMenus(true)} />
        }
        {toggleMenu && (
          <div className='navbar__menu-container scale-up-center'>
            <div className='navbar__menu-container-links'>
              <Menu />
              
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar